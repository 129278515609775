<template>
  <div>
    <v-form>
      <p class="mb-3">
        Two experiments are carried out under identical conditions using an optically pure sample of
        1-phenylethanol with propionic anhydride. One experiment was exposed to the
        <stemble-latex content="$\text{(}R\text{)-HBTM}$" />
        catalyst while the other used the
        <stemble-latex content="$\text{(}S\text{)-HBTM}$" />
        catalyst. The two experiments were followed using thin layer chromatography and the rate
        constant for each process was determined to be:
      </p>

      <v-simple-table class="mb-4 pl-7" style="max-width: 271px">
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{(}R\text{)-HBTM}$" />
            </td>
            <td>
              <chemical-latex content="\text{k}\,=\,0.029\,\text{s}^{-1}" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{(}S\text{)-HBTM}$" />
            </td>
            <td>
              <chemical-latex content="\text{k}\,=\,0.082\,\text{s}^{-1}" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI_51LPL6_Q4',
  components: {ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {
          text: 'The alcohol is $(R)\\text{-1-phenylethanol}$ since the reaction with $(R)\\text{-HBTM}$ was faster',
          value: 'a',
        },
        {
          text: 'The alcohol is $(R)\\text{-1-phenylethanol}$ since the reaction with $(S)\\text{-HBTM}$ was faster',
          value: 'b',
        },
        {
          text: 'The alcohol is $(S)\\text{-1-phenylethanol}$ since the reaction with $(R)\\text{-HBTM}$ was faster',
          value: 'c',
        },
        {
          text: 'The alcohol is $(S)\\text{-1-phenylethanol}$ since the reaction with $(S)\\text{-HBTM}$ was faster',
          value: 'd',
        },
      ],
    };
  },
};
</script>
